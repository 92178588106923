<template>
	<div class="vg_wrapper">
		<el-card>
			<div class="vd_search_group">
				<el-form :inline="true" size="mini" label-width="120px" :model="searchForm" ref="searchForm">
					<el-row class="vg_mb_5">
						<el-col :md="8" class="vg_mb_5">
							<el-form-item label="成品采购号：">
								<el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写成品采购号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :md="8" class="vg_mb_5">
							<el-form-item label="客户订单号：">
								<el-input size="small" v-model.trim="searchForm.popu_cust_no" clearable placeholder="请填写客户订单号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :md="8" class="vg_mb_5" v-if="this.$cookies.get('userInfo').acct_id === 1">
							<el-form-item label="供应商简称：">
								<el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商简称"></el-input>
							</el-form-item>
						</el-col>
						<el-col :md="8" class="vg_mb_5">
							<el-form-item label="打印状态：">
								<el-select filterable v-model="searchForm.pdf_status" placeholder="请选择打印状态" size="small" clearable>
									<el-option v-for="item in printStatusList" :key="item.id" :label="item.label" :value="item.id"/>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :md="8" class="vg_mb_5">
							<el-form-item label="采购经办人：">
								<el-input size="small" v-model.trim="searchForm.popu_stff_name" clearable placeholder="请填写采购经办人"></el-input>
							</el-form-item>
						</el-col>
						<el-col :md="24" class="vg_mb_5">
							<el-form-item label="要求交货日期：">
								<div class="block">
									<el-date-picker size="small" v-model="searchForm.timeValue" type="datetimerange" range-separator="至"
									                start-placeholder="开始日期" end-placeholder="结束日期"/>
								</div>
							</el-form-item>
							<el-form-item>
								<el-button size="small" type="primary" icon="el-icon-search" @click="getPopusNow()" class="vg_ml_16">查询</el-button>
								<el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div class="vd_button_group vg_mtb_16">
			</div>
			<el-row>
				<el-col :md="24">
					<el-tabs v-model="activeName" type="card" @tab-click="switchTabs">
						<el-tab-pane label="已下发" name="2"></el-tab-pane>
						<el-tab-pane label="已完结" name="3"></el-tab-pane>
						<el-tab-pane label="部分失效" name="1"></el-tab-pane>
						<el-tab-pane label="已失效" name="4"></el-tab-pane>
					</el-tabs>
					<el-table class="vg_cursor" ref="multiTable" :data="tableData"
					          @row-dblclick="dbClickJp" border v-loading="loadFlag">
						<el-table-column label="成品采购号" prop="podr_no" />
						<el-table-column label="客户订单号" prop="popu_cust_no" />
						<el-table-column label="供应商简称" prop="supp_abbr"/>
						<el-table-column label="货号款数" prop="popu_prod_num"/>
						<el-table-column label="变更次数" prop="chan_num">
							<template v-slot="scope">
                <span v-if="scope.row.chan_num > 0 && scope.row.chan_status === 1">
                  <el-badge :is-dot="scope.row.chan_status === 1" class="item">
                    <span>{{scope.row.chan_num}}</span>
                  </el-badge>
                </span>
								<span v-else-if="scope.row.chan_num > 0 && scope.row.chan_status === 0">
                  <span style="color: #000;font-weight: bold">{{scope.row.chan_num}}</span>
                </span>
								<span v-else>
                  <span style="color: #0e932e">{{scope.row.chan_num}}</span>
                </span>
							</template>
						</el-table-column>
						<el-table-column label="合同金额" prop="popu_total" />
						<el-table-column label="客户简称" prop="cust_abbr" />
						<el-table-column label="采购经办人" prop="popu_stff_name" />
						<el-table-column label="合同日期" prop="popu_date" :formatter="formatDate" />
						<el-table-column label="要求交货日期" prop="deli_date" :formatter="formatDate" />
						<el-table-column v-if="acct_id === 1" label="打印状态" prop="pdf_status" min-width="50" align="center">
							<template v-slot="scope">
								<span v-if="scope.row.pdf_status === 0" class="vd_cd81e06">未打印</span>
								<span v-if="scope.row.pdf_status === 1" class="vd_c4dbf2a">已打印</span>
							</template>
						</el-table-column>
						<el-table-column v-if="acct_id === 1" label="备注" prop="popu_remark" align="center">
							<template v-slot="scope">
								<el-input v-model="scope.row.popu_remark" disabled show-word-limit placeholder="暂无">
									<template #append>
										<el-popover placement="top" width="500" trigger="manual" v-model="scope.row.remarkShow">
											<el-input type="textarea" :rows="5" v-model="remark" clearable placeholder="请填写备注"></el-input>
											<div style="display: flex;margin-top: 10px">
												<el-button style="margin-left: auto" type="primary" size="mini" @click="saveRemark(scope.$index)">保存</el-button>
											</div>
											<el-button slot="reference" type="text" size="mini" @click="editRemark(scope.$index)">编辑</el-button>
										</el-popover>
									</template>
								</el-input>
							</template>
						</el-table-column>
						<el-table-column v-if="acct_id === 1" label="操作" prop="popu_total" align="center">
							<el-button type="text">查看生产控制表</el-button>
						</el-table-column>
						<el-table-column label="单据状态" show-overflow-tooltip align="center">
							<template v-slot="scope">
								<el-tag :type="getStatusName(scope.row.popu_status).type" size="mini">
									{{ getStatusName(scope.row.popu_status).name }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column label="关联材料订单" align="center" >
							<template v-slot="scope">
								<el-link v-if="scope.row.puca_total" class="vg_cursor" type="primary" @click="relationDelivery(scope.row)">共{{scope.row.puca_total}}单</el-link>
								<span v-else class="vg_9f9a9a">暂无</span>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :md="24">
					<pubPagination :totalPage='totalPage' @changePageSearch="changePageSearch" ref="pubPagination"/>
				</el-col>
			</el-row>
			<!-- 关联送货单 -->
			<el-dialog
					:visible="relationDeliveryFlag"
					@close="relationDeliveryFlag = false"
					top="30vh" center
					width="50%">
				<el-row class="vd_dis_cen vg_mb_16">
					<el-table
							border
							:data="relationDeliveryList"
							style="width: 100%">
						<el-table-column label="序号" width="80" align="center">
							<template v-slot="scope">
								{{ scope.$index + 1 }}
							</template>
						</el-table-column>
						<el-table-column
								prop="dent_no"
								align="center"
								label="采购号码">
							<template v-slot="scope">
								<el-link @click="jumpDentDetail(scope.row)" type="primary" class="vg_cursor">{{scope.row.modr_no}}</el-link>
							</template>
						</el-table-column>
						<el-table-column label="状态" prop="puca_status" min-width="50" align="center">
							<template v-slot="scope">
								<el-tag v-if="scope.row.puca_status === 0"  type="info"	size="small" >未下发</el-tag>
								<el-tag v-else-if="scope.row.puca_status === 1" type="warning"	size="small" >部分失效</el-tag>
								<el-tag v-else-if="scope.row.puca_status === 2"  size="small">已下发</el-tag>
								<el-tag v-else-if="scope.row.puca_status === 3"  type="success"	size="small">已完结</el-tag>
								<el-tag v-else-if="scope.row.puca_status === 4"  type="danger"	size="small">已失效</el-tag>
							</template>
						</el-table-column>
					</el-table>
				</el-row>
				<el-row class="vd_dis_right">共{{relationDeliveryList.length}}单</el-row>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
import {get, post} from "@api/request";
import helper from "@assets/js/helper.js"
import pubPagination from "@/components/common/pubPagination";
import {popuAPI} from "@api/modules/popu";
import {matrAPI} from "@api/modules/matr";

export default {
	name: "PopuList",
	components: {
		pubPagination,
	},
	data() {
		return {
			tableData: [],
			searchForm: {
				page_no: 1,
			},
			totalPage: 0,
			btn: {},
			loadFlag: false,
			multiSelection: [],
			relationDeliveryFlag: false,
			relationDeliveryName: '',
			relationDeliveryList: [],
			currentPage: 1,
			printStatusList: [
				{id: 0, label: '未打印'},
				{id: 1, label: '已打印'},
			],
			statusList: [
				{
					id: 0, label: '草拟'
				}, {
					id: 1, label: '在批'
				}, {
					id: 2, label: '生效'
				}
			],
			stffUserList: [],
			custOptionList: [],
			activeName: 'first',
			suppList: [],
			acct_id: this.$cookies.get("userInfo").acct_id,
			remark: '',
		};
	},
	created() {
		this.initData()
	},
	watch: {
		$route(to, from) {
			if (from.path === '/popu_edit' || from.path === '/popu_add') {
				this.initData()
			}
		},
	},
	filters: {
		formatDate1(row) {
			return helper.toTimeDay(row)
		},
	},
	methods: {
		initData() {
			this.getPopusList()
		},
		//跳转对应的送货详情
		jumpDentDetail(row){
			this.relationDeliveryFlag = false;
			this.jump('/puca_edit', {perm_id: 142, form_id: row.puca_id, autoFlag: 0})
		},
		// 打开关联送货单弹框
		relationDelivery(row){
			get(popuAPI.getPucaByPopuId, {popu_id:row.popu_id}).then(res => {
				if (res.data.code === 0) {
					this.relationDeliveryName = row.modr_no;
					this.relationDeliveryList = res.data.data.list;
					this.relationDeliveryFlag = true;
				}
			});
		},
		getStatusName(status) {
			switch (status) {
				case 0:
					return {name: '未下发', type: 'info'}
				case 1:
					return {name: '部分失效', type: 'warning'};
				case 2:
					return {name: '已下发', type: 'primary'};
				case 3:
					return {name: '已完结', type: 'success'};
				case 4:
					return {name: '已失效', type: 'danger'};
			}
		},
		/**
		 * 获取页面数据
		 */
		getPopusList() {
			const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
			this.searchForm.start_time = timeNewVal.startTime
			this.searchForm.end_time = timeNewVal.endTime
			this.searchForm.page_no = this.currentPage;
			get(popuAPI.getPopus,this.searchForm).then(({data}) => {
				if (data.code === 0) {
					this.loading = false;
					this.tableData = data.data.list;
					this.totalPage = data.data.total;
					this.btn = data.data.btn;
					setTimeout(() => {
						this.loadFlag = false
					}, 500);
				} else {
					this.$message.error(data.msg)
				}
			})
		},
		/**
		 * 刷新
		 */
		buttonRefresh() {
			this.searchForm = {page_no: 1};
			this.loadFlag = true
			this.currentPage = 1
			this.initData()
			this.$refs.pubPagination.currentPage = 1
		},
		/**
		 * 查询方法
		 */
		getPopusNow() {
			this.loadFlag = true
			this.$refs.pubPagination.currentPage = 1
			this.currentPage = 1
			this.initData()
		},
		/**
		 * 双击row跳转到详情编辑
		 */
		dbClickJp(row) {
			this.jump("/popu_edit", { perm_id: this.$route.query.perm_id, form_id: row.popu_id });
			post(popuAPI.editPopuChanStatus,{popu_id:row.popu_id,chan_status:0,chan_num:row.chan_num})
					.then(({data}) => {
						if (data.code === 0) {
							this.getPopusList();
						}
					})
		},
		/**
		 * 分页查询
		 */
		changePageSearch(val) {
			this.loadFlag = true
			this.currentPage = val
			this.getPopusList()
		},
		/**
		 * 时间转换
		 */
		formatDate(row) {
			return this.helper.toTimeDay(row.create_time)
		},
		/**
		 * 切换选项卡
		 */
		switchTabs(tab, event) {
			this.searchForm.popu_status = Number(this.activeName);
			this.getPopusList();
		},
		saveRemark(index) {
			this.tableData[index].popu_remark = this.remark;
			this.tableData[index].remarkShow = false;
			this.editPopuRemark(this.tableData[index]);
		},
		editRemark(index) {
			this.remark = '';
			this.tableData[index].remarkShow = true;
		},
		// 编辑修改首页备注
		editPopuRemark({popu_id,popu_remark}) {
			post(popuAPI.editPopuRemark, {popu_remark: popu_remark, popu_id: popu_id}).then(({data}) => {
				if (data.code === 0) {
					this.getPopusList();
				}
			});
		},
	}
};
</script>

<style scoped lang="scss">
.vd_elimg {
	width: 60px;
	height: 60px;
}
</style>
